@import '../../styles/vars';


.monitor{
    .color-blue{
        color: $blueColor;
    }

    .routes-map, .machines-list{
        .card-header{
            background-color: $titlesColor;
            color:#fff;
        }
    }

    h6 + label{
        font-size: 22px;
        font-weight: 200;
    }

    .brd-right{
        border-right: 1px solid #BDBDBD;
    } 

    .brd-left{
        border-left: 1px solid #BDBDBD;
    }
}