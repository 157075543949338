@import '../../styles/vars';

#wrapper {
    #sidebar-wrapper {
        z-index: 500;
        position: fixed;
        left: 0;
        top:70;
        width: 198px;
        height: calc(100vh - 56px);
        //margin-left: -100px;
        overflow-y: auto;
        background: $titlesColor;
        padding: 0px;
        margin: 0px;
        

        .sidebar-nav {
            //position: absolute;
            top: 0;
            max-width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
        
            li {
                //text-indent: 6px;
                //line-height: 40px;
                //height: 70px;
                width: 100%;
                //text-align: center;
                //padding-bottom:1em;
                border-radius: 0px;
                border-left: 10px solid #fff;
                margin-bottom: 5px;
                margin-top: 5px;
                //border-top:5px solid $titlesColor;
                //border-bottom:5px solid $titlesColor;

                i{
                    width: 28px;

                }

                p{
                    margin:0;
                }
        
                a {
                    display: block;
                    text-decoration: none;
                    color: #fff;
                    padding: 1em;
                    //font-size: 14px;
                    border-radius: 0px;
                    
                    &.active{
                        background-color: #fff;
                       color:$titlesColor;
                    }
        
                    
                }
            }
        

        
            .sidebar-brand{
                height: 65px;
                font-size: 18px;
                line-height: 60px;
        
                a {
                    color: #a2a2a2;
                    &:hover {
                        color: #fff;
                        background: none;
                    }
                }
            }
        }
    }

    #page-content-wrapper {
        max-width: 100vw;
        //position: absolute;
        min-height: 85vh;
        background-color: #eee;
        //padding-bottom: 20px;
        margin-top:58px;

        .page-container{
            margin-left:198px;
            padding:1em;

        }
        
    }
}

.navbar.fixed-top{
    a{
        color: $titlesColor;
    }
    i{
        font-size: 30px;
        padding-right:.5em;
    }

}

.select-city{
    border:none;
    font-weight: 700;
    color:$turquesaColor;
    font-size: 20px;
    &:active,&:focus{
        outline: none;
        box-shadow: none;
    }
}

.bg-white{
    background-color: #fff;
}
