@import './vars';

.bg-dark-blue{
    background-color: $titlesColor;
}

.sv-btn{
    border-radius: 25px;
    //font-size: 20px;
    padding-top: .2em;
    padding-bottom: .2em;
    margin-right: .2em;
    
}

.btn-submit{
    background-color: $turquesaColor;
    color:#fff;
    //font-weight: 500;
    &:hover{
        color:#fff;
    }
}

.label-title{
    color: $titlesColor;
    font-weight: 500;
}

.tac{
    text-align: center;
}

.tar{
    text-align: right;
}

.table{
   th{
    border-top:none;
   }
}

.btn-edit-table{
    background-color: $editColor;
    color:#fff;
    border-radius: 50%;
    padding:.2em .3em .2em .5em;
    margin-right: .2em;
    &:hover{
        color: $editColor;
        background-color:#fff;
        border: 1px solid $editColor;
    }
}

.details{
    .data{
        font-size: 18px;
        font-weight: 400;
    }
}


.separate{
    margin-top: 1em;
    margin-bottom: 1em;
}

.btn-violet{
    background-color: $violet;
    color:#fff;
    //font-weight: 500;
    &:hover{
        color:#fff;
    }
}

.pd-p{
    padding-top:2.5em;
}

.hide{
    display: none;
}

.rbc-btn-group{
    button{
        padding:.2em .5em;
    }
}

.rbc-active{
    background-color: #136DF4 !important;
    box-shadow: none !important;
    color: #fff !important;
}
.rbc-header{
    span{
        color: #000 !important;
    }
}
.rbc-row>div:first-child,.rbc-row>div:last-child{
    color:#CC2828 !important;
}

.light-pd{
    padding: .5em;
}

.img{
    // width: 50%;
    // height: auto;
}

.code-bar{
    width: 108px;
    height: 71px;
}

.no-row-margin{
    margin: 0px;
}
