


.no-pd-lr{
    margin-left: 0;
    margin-right: 0;
}

.section-details{
    .card:first-child {
        margin-right: 5px;
        padding:0;
    }
    .card:not(:first-child) {
        margin-left: 5px;
    }
}

.calendar-section{
    height: 600px;
    padding:1em .5em 5em .5em !important;

}

.visit{
    .card-header{
        background-color:#136DF4;
        color:#FFF;
        padding: .5em;
    }
}

.visited{
    .card-header{
        background-color:#00B14B;
        color:#FFF;
        padding: .5em;
    }
}
.machines-list{
    td{
        border-top:1px solid #fff !important;
        
        //border: 1px solid #fff;
        //border-radius: 5px;
        padding:.3em ;
    }
}
.selectedMachines{
    tbody{
        tr{
            
        }
        td{
            border-top:1px solid #fff !important;
            background-color: #007bff;
            color:#fff;
            //border: 1px solid #fff;
            //border-radius: 5px;
            padding:.3em ;
        }

        tr td:first-child {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }

        tr td:last-child {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
}

.rbc-event{
    background-color: unset !important;
    padding:0 !important;
    border-radius: unset !important;
    .rc-event-container{
        white-space: normal;
        .bg-indicator{
            width: 25px;
            height: 25px;
            color:#fff;
            border-radius: 30px;
            text-align: center;
            //display: inline-block;
            //margin: .2em .5em;
        }
    }
}

.cont{
    padding-top: .5em;
    .bg-indicator{
        width: 15px;
        height: 15px;
    }
    label{margin:0}
}

.bg-indicator{
    width: 25px;
    height: 25px;
    color:#fff;
    border-radius: 30px;
    text-align: center;
    display: inline-block;
    //margin: .2em .5em;
}

    .bg-red{
        background-color: #FF0000;
    }

    .bg-green{
        background-color: green;
    }

    .bg-yellow{
        background-color: yellow;
    }

