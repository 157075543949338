@import '../../styles/vars';

.login{
    font-family: 'Nunito', sans-serif;
    display: flex;
    height: 100vh;
    background-size: contain;
    justify-content: center;
    align-items: center;
    padding: 1em;
    background-image: url('../../Imgs/img-login_720.jpg');
    //background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    .container{
        max-width: 35vw !important;
    }

    img{
        width: 100%;
        
    }
    .card{
        border-radius: 10px;
        padding: 1em;
    }

    .label-title{
        color: $titlesColor;
        font-weight: normal;
    }

    .welcome{
        text-align: center;
        font-size: 14px;
        padding-top:2em;
        padding-bottom:1em;
    }
    

    .btn{
        width: 100%;
        margin: 0;
        padding-top:.5em;
        padding-bottom:.5em;
        border-radius: 5px;
        &.btn-primary{
            //background-color: $blue-login !important;
        }
    }

    .forgot-password{
        text-align: center;
        padding: 1em 0 2em 0;
        a{
            //color: $links !important;
            font-size: 15px;
        }
    }

    .btn-show-password{
        position: absolute;
        padding-top:7px;
        right: 25px;
        z-index: 100;
        &:hover{
            cursor: pointer;
            color:#000;
        }
        &.active{
            color:#000;
        }
    }

    .color-primary{
        //color: $blue-login;
    }

    .divider{
        margin-top:.5em;
        margin-bottom:.5em;
    }
    
    .divider-top{
        margin-top:2em;
    }

    h5{
        margin-top: -15px;
        font-weight: 600 !important;
    }

   
    
}

.tac{
    text-align: center;
}

.separate{
    padding-top:.5em;
    padding-bottom:.5em;
}

.match{
    color:green;
}
.unmatch{
    color:red;
}